
import { defineComponent, inject, onMounted, ref} from "vue";
import { useRouter, useRoute } from "vue-router";
import Modal from "@/components/UI/Modal";
import Toast from "@/components/UI/Toast";
import axios from "@/api/axios";
import $ from "jquery";
import qs from "qs";
export default defineComponent({
  name: "RealVote",
  components: {
  },
  setup() {
    const personalName = ref()
    const staffListA = ref()
    const votingLimitA = ref()
    const limitData = ref(true)
    const router = useRouter();
    //  展示用户信息
    const userInfo = inject("userInfo") as any;


    onMounted(() => {
      const token = localStorage.getItem("token");
      if (!token) {
          router.push({
            name: "Login",
            query: {
                redirect: router.currentRoute.value.fullPath,
            },
          });
          return false;
      }
      $('.home-wapper').css('padding-top', '0')
      axios
        .post("/M/Staff/findWyListStaffByDeptNum")
        .then((res) => {
          const data = res.data
          if (data.success) {
            data.obj.staffList13.forEach((item: any) => {
              item.disable = false
              item.forbid = false
            });

            staffListA.value = data.obj.staffList13
            votingLimitA.value = data.obj.votingLimit13
            personalName.value = data.obj.currentStaff.staffName

          } else {
            limitData.value = false
            // Toast({
            //   type: "error",
            //   title: res.data.msg,
            // });
          }
        })
        .catch((err) => {
          console.log(err)
        });

    })

    function handleChangeA(item: any, index: any) {
      let sum = 0
      staffListA.value.forEach((item: any) => {
        if (item.disable==true) {
          sum += 1
        }
      })
      if (sum>=votingLimitA.value) {
        staffListA.value.forEach((item: any) => {
          if (item.disable!=true) {
            item.forbid = true
          }
        })
      } else {
         staffListA.value.forEach((item: any) => {
            item.forbid = false
        })
      }
    }


    function viewStatic() {
      const token = localStorage.getItem("token");
      if (!token) {
          Modal({
              title: "温馨提示",
              content: "登录后才可以进行投票！",
              onClose: () => {
                  router.push({
                      name: "Login",
                      query: {
                          redirect: router.currentRoute.value.fullPath,
                      },
                  });
              },
          });
          return false;
      } else {
        router.push({
          name: "RealVoteView"
        });
      }
    }

    function save() {
      const staffData: any = []
      staffListA.value.forEach((item: any) => {
        if(item.disable == true) {
          staffData.push(item.id)
        }
      })

      if (staffData.length==0) {
        Toast({
          type: "error",
          title: '请选择您要投票的人！',
        });
        return false
      }
      const data = qs.stringify({
        beVotedIds: staffData.join(',')
      });
      const token = localStorage.getItem("token");
      if (!token) {
          Modal({
              title: "温馨提示",
              content: "登录后才可以进行投票！",
              onClose: () => {
                  router.push({
                      name: "Login",
                      query: {
                          redirect: router.currentRoute.value.fullPath,
                      },
                  });
              },
          });
          return false;
      } else {
        axios
        .post("/M/Staff/doWyVoting", data, {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
              onClose: () => {
                router.push({
                  name: "RealVoteView"
                });
              },
            });

          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err)
        });
      }
    }

    const clearUserInfo = inject("CLEAR_USERINFO") as () => void;
    function outLogin() {
      localStorage.removeItem("token");
      clearUserInfo();
      router.push({
        name: "Login",
        query: {
            redirect: router.currentRoute.value.fullPath,
        },
      });
    }

    return {
      userInfo,
      handleChangeA,
      save,
      viewStatic,
      staffListA,
      votingLimitA,
      limitData,
      outLogin,
      personalName
    }
  },
});
